import("/src/components/faq/faq.js");
import("/src/components/form/form.js");
import("/src/templates/base/legacy.js");
import("/src/templates/base/swiper.js");
import("/src/templates/base/map.js");

(() => {
  const kwBody = document.querySelector("body.kw-body");

  const callbackBtn = document.getElementById("kw_cta_callback_mob");
  const ctaPhotoBtn = document.getElementById("kw_cta_photo");

  const callbackDialog = document.getElementById("kw_dialog_callback");

  const videoDialog = document.getElementById("kw_dialog_video");
  const videoWrap = videoDialog?.querySelector("div.kw-dialog_video-wrap");
  const videoCloseBtn = videoDialog.querySelector("button.kw-button_close");
  const ctaCloseBtn = callbackDialog?.querySelector("button.kw-button_close");

  const bodyScroll = (force = true) => {
    if (force) {
      kwBody.classList.remove("kw-noscroll");
    } else {
      kwBody.classList.add("kw-noscroll");
    }
  };
  const dialogClose = (dialog, cb = () => {}) => {
    cb();
    bodyScroll(true);
    dialog.close();
  };
  const detectOutsideDialogClick = (e, dialog, cb = () => {}) => {
    e.stopPropagation();
    const rect = dialog?.getBoundingClientRect();
    if (!rect) return;
    if (
      e.clientY < rect.top ||
      e.clientY > rect.bottom ||
      e.clientX < rect.left ||
      e.clientX > rect.right
    ) {
      dialogClose(dialog, cb);
    }
  };
  const videoDialogReset = () => {
    videoWrap.innerHTML = "";
  };

  const showCtaDialog = (e) => {
    e.stopPropagation();
    bodyScroll(false);
    callbackDialog?.showModal();
  };

  ctaPhotoBtn.onclick = showCtaDialog;
  callbackBtn.onclick = showCtaDialog;
  videoCloseBtn.onclick = (e) => {
    e.stopPropagation();
    dialogClose(videoDialog, videoDialogReset);
  };
  ctaCloseBtn.onclick = (e) => {
    e.stopPropagation();
    dialogClose(callbackDialog);
  };
  videoDialog.onclick = (e) =>
    detectOutsideDialogClick(e, videoDialog, videoDialogReset);
  callbackDialog.onclick = (e) => detectOutsideDialogClick(e, callbackDialog);

  const videoBlocks = document.querySelectorAll(".kw-video");

  videoBlocks.forEach((item) => {
    item.onclick = () => {
      const iframe = item.querySelector("template")?.content?.cloneNode(true);
      if (!iframe) {
        return;
      }
      bodyScroll(false);
      videoWrap.innerHTML = "";
      videoWrap.appendChild(iframe);
      videoDialog.showModal();
    };
  });

  const openMenu = () => {
    document.querySelector("nav.kw-nav")?.classList?.toggle("show");
  };

  const navClose = document.getElementById("kw_nav_close");
  const menuBtn = document.getElementById("kw_mob_menu");
  const menuBtnLegacy = document.querySelector(".app-header .menu_btn");
  menuBtn?.onclick = openMenu;
  menuBtnLegacy?.onclick = openMenu;
  navClose?.onclick = openMenu;

  const fire = (msg, mod = "success") => {
    if (mod == "success") {
      const dialogList = document.getElementsByTagName("dialog");
      for (const el of dialogList) {
        el?.close();
      }
    }
    const newDiv = document.createElement("div");
    newDiv.innerHTML = `<span class="kw-callback-msg ${mod}">${msg}</span>`;
    videoWrap.innerHTML = "";
    videoWrap.appendChild(newDiv);

    bodyScroll(false);
    videoDialog.showModal();
  };

  document.addEventListener("fetchit:success", (e) => {
    const { response } = e.detail;
    fire(response.message);
  });
  document.addEventListener("fetchit:error", (e) => {
    const { response } = e.detail;
    fire(response.message, "error");
  });
})();
